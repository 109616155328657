.leaderboard h3{
    text-align: center;
    font-weight: 800;
    font-size: 34px;
    color: #001adb;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 35px;
}

/* CSS to make the table full screen */
.leaderboard-table-container {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Centers the table horizontally */
    align-items: center; /* Centers the table vertically */
}

.leaderboard-table {
    width: 100%; /* Slight padding from the edges */
    border-collapse: collapse;
}

.leaderboard-table th, .leaderboard-table td {
    padding: 13px;
    text-align: left;
    border: 1px solid #0f254461;
    font-size: 17px;
    letter-spacing: 1px;
}

.leaderboard-table th {
    background-color: #0f2544;
    color: #fff;
    letter-spacing: 1px;
}
.leaderboard {
    margin-top: 10px;
}
.week-box {
    padding: 5px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.week-box.active {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
}

.slick-prev:before, 
.slick-next:before {
    color: black;  /* Style the arrow icons */
}

/* .week-carousel {
    margin-bottom: 10px;
} */

.error-message {
    color: red;
    margin-top: 10px;
}
.team-logo-row {
    display: flex;
    flex-direction: row;      /* Arrange logos in a row */
    justify-content: center;  /* Center the row of logos */
    gap: 20px;                /* Space between each team */
}

.team-logo-wrapper {
    display: flex;
    flex-direction: column;   /* Stack image and name vertically */
    align-items: center;      /* Center align each team image and name */
}

.team-logo {
    margin-bottom: 5px;       /* Space between the image and the name */
}

.team-name {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #1c921c;
    text-transform: uppercase;     /* Center the team name below the image */
}

.team-name.highlight {
    color: red;  /* Change text color to red */
}


@media only screen and (max-width:768px){
    .leaderboard {
        margin-top: 30px;
    } 
    .leaderboard h3 {
        margin-bottom: 20px;
    }
}

