*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.table-section .table td{
    padding-right: 1px !important;
}
.table-section .table th{
    padding: 5px !important;
}
.table-section {
    background: #fff3e9;
}
.table-section .table thead tr {
    background: #fc5400;
    color: white;
    font-size: 15px;
    /* border: 1px solid black; */
    letter-spacing: 1px;
}
table.table {
    width: 100%;
    overflow-x: scroll;
}
.table-section .table tbody tr{
    font-size: 14px;
    border: 1px solid black;
 

}

.table-section tbody tr td:nth-child(1) {
    background: #ffe7d5;
}
.table-section tbody tr td:nth-child(2) {
    background: #ffe7d5;
}
.table-section tbody tr td:nth-child(3) {
    background: #ffe7d5;
}
.table-section tbody tr td:nth-child(4) {
    background: #b00000;

    color: white;
}
.table-section tbody tr td:nth-child(5) {
    /* background: #ffe7d5; */
    /* color: white; */
}
.table>:not(caption)>*>* {
   
    border-bottom-width: 0px !important;
   
}
.slide-div .slick-slider.slick-slider {
    background: #ffcca6 !important;
}

.table-section tbody tr td:last-child{
    background-color: #ffe7d5;
}
.table-section tbody tr .h-green{
    background-color: #fff;
}
.table-section tbody tr .m-green{
    background-color: #aeffae;
}
.table-section tbody tr .d-green{
    background-color: #d5ffd5 ;
}
.table-section tbody tr .w-green{
    background-color: #f7fff7 ;
}

.spread {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spread p strong {
    color: black;
    font-size: 19px;
}


.spread ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: 1rem;
    background: linear-gradient(45deg, #dedede, #4cff4c);
    padding: 0px 10px;
    border: 1px solid black;
    
}

.spread ul li {
  margin-left: 37px;
}
.spread ul li:nth-child(1) {
    margin-left: 5px;
  }


  table.table tr td sub {
    position: absolute;
    bottom: 8px;
    right: 1px;
}
.table-section table.table tr td {
    position: relative;
    padding: 7px 2px;
    font-size: 11px;
    font-weight: 600;
}
.table-section table.table tr td i {
    color: #e3ab1f;
}
ul.star-list li i {
    color: #dba723;
    font-size: 18px;
}
ul.star-list {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
}
.grid-content {
    padding-top: 20px;
    background: #fff3e9;
    padding-bottom: 20px;
}
.grid-content ul li {
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 10px;
    line-height: 30px;
    font-size: 17px;
}
.surviour-heading {
    text-align: center;
}
.surviour-heading h2 {
    font-weight: 800;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.surviour-heading p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
}
.surviour-stategies {
    margin: 60px 0px 60px 0px;
}
.surviour-stategies h2 {
    font-weight: 800;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 50px;
    text-align: center;
    overflow: hidden;
    clear: both;
}
.article-box {
    margin-top: 50px;
}
.article-box.gridarticle-box .article-content h4 {
    text-transform: uppercase;
}
.article-box.gridarticle-box img {
    height: 210px;
    object-fit: cover;
    width: 100%;
}
.article-box.gridarticle-box .article-content {
    margin-top: 20px;
    padding-left: 20px;
}
.article-box.gridarticle-box .read_more {
    margin-bottom: 20px;
}
section.survvivor-strategy-blk.insidescop.surviour-expert {
    margin-bottom: 5px;
}
.survoiur-loadmore{
display: none;
}
.surviour-top {
    background: #fff3e9;
    padding: 60px 0px;
}

p.catch-latest {
    color: #fff !important;
}
@media only screen and (max-width:768px){
    .table-section {
      
        overflow-x: scroll;
    }
    .Surviour-btn.upgrade-btn a {
        padding: 18px 25px;
    }
    .Surviour-btn.upgrade-btn.grid-btn-circa a {
        padding: 18px 38px;
    }
    .survoiur-loadmore{
        display:block;
        }
    .surviour-heading {
        text-align: center;
    }
    .surviour-top {
        background: #fff3e9;
        padding: 30px 0px;
    }
    .surviour-heading h2 {
        font-size: 30px;}
        .surviour-heading p {
            padding: 0px 2%;
        }
        .surviour-stategies h2 {
            font-size: 30px;margin-bottom: 25px;}
            .article-box.gridarticle-box {
                margin-top: 10px !important;
            }
            .surviour-stategies {
                margin: 30px 0px 30px 0px;
            }  
            .Surviour-btn.load-morebtn.survoiur-loadmore a {
                border-radius: 10px;
                letter-spacing: 2px;
            }
            section.survvivor-strategy-blk.insidescop.surviour-expert h2 {
                padding: 0px 14%;
            }
            .Surviour-btn.load-morebtn.survoiur-loadmore a {
                border-radius: 10px;
            }
            .Surviour-btn.load-morebtn.survoiur-loadmore {
                margin-top: 30px;
                margin-bottom: 60px;}
                section.survvivor-strategy-blk.insidescop.surviour-expert {
                    padding: 30px 0px 0px 0px;
                }
}