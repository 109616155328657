.survivor-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.survivor-heading h2{
    font-weight: bold;
}

.week-filter {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 16px;
    color: #333;
    gap: 10px;
    justify-content: center;
}

.week-filter label {
    margin-right: 10px;
    font-weight: bold;
    letter-spacing: 1px;      
}
.week-filter button {
    padding: 13px 40px;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    background-color: #fc5400;
    border: none;
    font-weight: 700;
    letter-spacing: 1px;
}

.week-filter select {
    padding: 10px;            
    border: 1px solid #ccc;    
    border-radius: 4px;        
    font-size: 16px;           
    cursor: pointer;           
    transition: border-color 0.3s; 
}

/* Change border color on focus */
.week-filter select:focus {
    border-color: #007bff;     /* Change border color on focus */
    outline: none;              /* Remove outline */
}

/* Optional: Style for hover effect */
.week-filter select:hover {
    border-color: #0056b3;     /* Change border color on hover */
}


.poll-matchups {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    margin-top: 50px;
}

.poll-matchup {
    flex: 0 0 48%;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #0f2544;

}
.team-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    
}

.link {
    display: flex;              
    align-items: center;        
    text-decoration: none;      
    color: inherit;            
    margin-right: 10px;        
    padding: 10px;                     
    border: 1px solid #ddd;            
    border-radius: 5px;                
    background-color: #fc5400;        
    transition: background-color 0.3s;
}

.selected {
    background-color: #4caf50; /* Change to your desired background color */
    align-items: center;        
    text-decoration: none;      
    color: inherit;            
    margin-right: 10px;        
    padding: 10px;                     
    border: 2px solid #ad3333;            
    border-radius: 5px;                     
    transition: background-color 0.3s;
}

.team-logo-wrapper {
    display: flex;              /* Flexbox to center logo */
    align-items: center;        /* Center logo vertically */
}

.team-logo {
    width: 32px;               /* Set width */
    height: 32px;              /* Set height */
}

.team-names {
    margin-left: 5px;          /* Gap between image and name */
}

.vs {
    margin: 0 10px; 
    color: white;
}
.team-names{
    color:white;
}
.clubs{
    color: white;
    font-size: 18px;
    margin-bottom: 4px;
    letter-spacing: 1px;
}
.date-day{
    color: white;
    font-size: 13px;
    letter-spacing: 1px;
}


.team-button {
    background-color:  #fc5400;;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
}

.team-button:hover {
    background-color: #0056b3;
}

.vs {
    font-size: 18px;
    font-weight: bold;
}

.matchup-date{
    font-size: 14px;
    text-align: center;
    color: #dad0d0;
    margin-top: 10px;
}
.error-message {
    text-align: center; 
    color: red; 
    margin: 10px 0; 
    font-weight: bold; 
}
.success-message {
    text-align: center; 
    color: rgb(50, 230, 74); 
    margin: 10px 0; 
    font-weight: bold; 
}
.selection-message {
    text-align: center;
    color: rgb(4 145 23);
    margin: 10px 0;
    font-weight: bold;
    letter-spacing: 1px;
}
.no-content{
    text-align: center;
    font-weight: bold;
}
.leaderboard h3{
    text-align: center;
    font-weight: bold;
    padding-bottom: 10px;
}

/* CSS to make the table full screen */
.leaderboard-table-container {
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Centers the table horizontally */
    align-items: center; /* Centers the table vertically */
}

.leaderboard-table {
    width: 90%; /* Slight padding from the edges */
    border-collapse: collapse;
}

.leaderboard-table th, .leaderboard-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd; /* Basic border for clarity */
}

.leaderboard-table th {
    background-color: #f2f2f2;
}
.pool-heading {
    text-align: center;
}
.pool-heading h2 {
    font-weight: 800;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 10px;
}
.pool-heading h5 {
    color: #001adb;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
}
.select-week {
    position: relative;
}
select#week {
    word-wrap: normal;
    padding: 12px 15px;
    border-radius: 10px;
    border: 1.5px solid #fc5400;
    color: #fc5400;
    font-weight: 600;
    letter-spacing: 1px;
    appearance: none;
    text-align: left;
    background-color: white;
    appearance: none;
    width: 100%;
    min-width: 160px;
}
.select-week:after {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAATCAYAAAD4f6+NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjAzOTEyREM2Q0Y0MTFFRkI5ODBFN0JENUYzQjQ5NTgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjAzOTEyREQ2Q0Y0MTFFRkI5ODBFN0JENUYzQjQ5NTgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MDM5MTJEQTZDRjQxMUVGQjk4MEU3QkQ1RjNCNDk1OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MDM5MTJEQjZDRjQxMUVGQjk4MEU3QkQ1RjNCNDk1OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl2j8yUAAACpSURBVHjazNbPCoAgDMDhGT1O0Mt4E3q4oFuPWNH6QxBqbtPUwe+w24dephYNHQC0UMcsB6THRqwpjNmwQZ0sDaYw6sS0M0zqeatyqAdzLOr1gflRL4wFyoyyME5QJpQT4wX9jPJiPkE/oT4xQVBiVBBDAiVCkTBkUCSKjGGBhCgWhg1iotgYEYiIEmHEoABKjIkCeVBRmDQXFaKw9c7UceZdqCSYXYABAGJeWwYCXBCiAAAAAElFTkSuQmCC);
    position: absolute;
    width: 18px;
    display: block;
    top: 0;
    right: 18px;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    pointer-events: none;
}
.team-row .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 10px 10px;
    border-radius: 8px;
    background-color: #fc5400;
    transition: background-color 0.3s;
    border: none;
}
.team-names {
    font-size: 15px;
}
.poll-section p {
    text-align: center;
    letter-spacing: 1px;
} 
.team-row a.link.selected {
    background: #001adb;
}
@media only screen and (max-width:768px){
    .background-image.desktop-backimg.surviour-back {
        display: block;
    }
    .week-filter {flex-direction: column;}
    select#week {
        padding: 15px 15px;
        border-radius: 10px;
        border: 2px solid #fc5400;
        min-width: 260px;
    }
    .week-filter button {
        padding: 13px 40px;
        border-radius: 8px;
        min-width: 260px;
    }
    .team-row {
        flex-direction: column;
    }
    .poll-matchup {
        flex: 0 0 100%;
        text-align: center;
    }
        .team-row .link {min-width: 220px;} 
        .vs {
            margin: 10px 0px;
        }  
        .pool-heading h2 {
            font-size: 45px;} 
                    
}
