.circa-part {
    background-color: #fff3e9;
}

.circa-part {
    background-color: #fff3e9;
    padding: 70px 0px 40px 0px;
}
.Surviour-btn.load-morebtn button {
    background: #fc5400;
    padding: 18px 50px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 600;
    border:none;
}
.divs ul li {
    background: #fff;
}
.circa-heading h2 {
    font-weight: 800 !important;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.forget-sign {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
a.sign-up1 {
    color: #fc5400 !important;
    text-decoration: underline;
    letter-spacing: 2px;
    font-weight: 600;
}
.circa-heading {
    text-align: center;
}

.circaselect-box select {
    word-wrap: normal;
    width: 100%;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1.5px solid #fc5400;
    color: #fc5400;
    font-weight: 600;
    letter-spacing: 1px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: left;
    background-color: white; 
    -moz-appearance: none; 
    -webkit-appearance: none;
    appearance: none;
}

.circaselect-box:after {
    content: "";
    background-image: url(../../public/images/down-arrow.png);
    position: absolute;
    width: 18px;
    display: block;
    top: 0;
    right: 18px;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    pointer-events: none;
}

.circaselect-box {
    position: relative;
    width: 250px;
    margin: auto;
    margin-top: 40px;
}

.circa-box1 {
    /* display: flex;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    background: #ffff;
    padding: 20px;
    box-shadow: 0px 0px 2px 0px #ddd;
    margin-top: 10px;
}

.circalright {
    /* width: 40%; */
    padding-left: 10px;
    margin-top: -6px;
}
.adam-slider-part .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054" !important;
    font-size: 30px;
    position: absolute;
    top: -178%;
    opacity: 5;
}
.adam-slider-part .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053" !important;
    font-size: 30px;
    position: absolute;
    top: -178%;
    opacity: 5;
    left: -5px
}
.adam-slider-part {
    margin-top: 20px;
}
.adam-slider-part .slick-prev:before, .adam-slider-part .slick-next:before {
    color: #fc5400 !important;
}
.cicracontent h4 {
    letter-spacing: 2px;
    font-size: 20px;
    color: #0f2544;
    FONT-WEIGHT: 600;
    FONT-FAMILY: 'Lato';
    margin-bottom: 0px;
}

.cicraleftt img {
    width: 35px;
}

.cicraleftt {
    margin-bottom: 4px;
}
.circalcenter {
    margin-top: -2px;
}
.circalcenter img {
    max-width: 100%;
    width: 100%;
    vertical-align: text-bottom;
    height: 35px;
}

.cicraleftt {
    display: flex;
    margin-top: 3px;
}
.circalleft {
    margin-top: 6px;
}
.cicracontent {
    margin-left: 13px;
}

/* .circalleft {
    width: 35%;
} */

.circalright h3 {
    color: #fc5400;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 27px;
    margin-bottom: 0px;
}

.article-img1 img {
    width: 100%;
}

.circa-box {
    margin-top: 60px;
    margin-bottom: 60px;
}

.survvivor-strategy-blk {
    background: #0f2544;
    padding-top: 70px;
    padding-bottom: 176px;
    margin-top: 70px;
}

.survvivor-strategy-blk p {
    color: #fff;
    max-width: 85%;
    margin: auto;
    text-align: center;
    letter-spacing: 1px;
    font-family: 'Lato';
    font-size: 17px;
    line-height: 30px;
}

.imgblk img {
    width: 100%;
}

.survvivor-strategy-blk .circa-heading h2 {
    font-weight: 400;
}

.grid-postcard-blk {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 60px;
}


.grid-postcard-blk .common-blk {
    margin-bottom: 70px;
}

.grid-postcard-blk .imgblk {
    max-width: 48px;
    margin: auto;
    margin-bottom: 50px;
}


.survvivor-strategy-blk h3 {
color: #fff;
letter-spacing: 1px;
font-weight: 700;
    text-transform: uppercase;
}

.article-headline-blk {
    background: #fff3e9;
    position: relative;
    margin-bottom: 0px;
}

.article-headline-blk .slick-slider {
    margin-top: -135px !important;
}

.article-headline-blk .slick-slider .slick-prev,
.article-headline-blk .slick-slider .slick-next {

    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 95% !important;
}


.article-headline-blk .slick-slider .slick-next:before {
    content: '→';
    position: absolute !important;
    left: -198px !important;
    bottom: -14px !important;
    opacity: 1 !important;
    z-index: 9999;
    width: 180px;
    height: 42px;
}
.article-headline-blk .slick-slider .slick-prev:before {
    content: '←'; 
    position: absolute !important;
    left: -198px !important; 
    bottom: -14px !important; 
    opacity: 1 !important;
    z-index: 9999; 
    width: 180px;
    height: 42px; 
}

.article-headline-blk .slick-slider button.slick-arrow.slick-prev {
    margin-left: 64px !important;
    z-index: 99;
    background: #00000000;
    width: 180px;
    height: 42px;
}

.article-headline-blk .slick-slider .slick-prev:before,
.article-headline-blk .slick-slider .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #ffffff00;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.circaselect-box .select-box {
    text-align: left;
}

.article-blk {
    background: #fff;
    padding: 40px;
    margin-top: 0px;
    border: 2px solid #fc5400;
    margin-bottom: 30px;
}

.article-blk h5 {
    color: #fc5400;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h2.heading {
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: 800 !important;
    font-size: 45px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
}
h2.heading.article-heading1 {
    font-size: 40px;
}



.article-blk p {
    font-family: 'Lato';
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 17px;
}
.read-more-less-btn {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    color: #fc5400;
    letter-spacing: 1px;
    border: 0px;
    background: none;
    display: block;
    text-decoration: underline;
}

.article-blk a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    color: #fc5400;
    letter-spacing: 1px;
}

.pre-next-button {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.outline-btn {
    text-transform: uppercase;
    color: #fc5400;
    font-weight: 800;
    border-radius: 15px;
    font-size: 15px;
    padding: 8px 15px;
    border: 1.5px solid #fc5400;
    background: transparent;
    letter-spacing: 2px;
    width: 200px;
}

.outline-btn i {
    margin-right: 4px;
    margin-left: 4px;
}

.select-box:after {
    content: "";
    background-image: url(../../public/images/down-arrow.png);
}


/*wade kitchen css start*/
section.wade-kitchen {
    background-image: url(../../public/images/wadekitchenback.jpg);
    padding: 80px 0px 0px 0px;
    background-position: center;
    margin-bottom: 140px;
    height: 550px;  
    /* position: relative; */
}
.wade-arcticle{
    margin-top: -600px;

}
.read_more{
    margin-bottom: 50px;
}



.latest-article h4 {
    margin-bottom: 0px;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    font-family: 'Lato';
    font-weight: 600;
}

.latest-article {
    background: #fd5401;
    padding: 15px 30px;
}

.article-img img {
    width: 35%;
}

.article-part {
    background: #fff;
    padding: 0px 30px;
    border: 1.5px solid #fc5400;
}

.date-img {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 60px;
    padding-bottom: 25px;
}

.article-img {
    position: absolute;
    right: 0;
    top: -96px;
}

.article-img {
    text-align: right;
}

.article-img img {
    width: 50%;
}

.article-date h5 {
    color: #fc5400;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}

.article-heading h2 {
    font-weight: 800;
    font-size: 40px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.article-heading p {
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.article-btn a {
    text-transform: uppercase;
    font-weight: 600;
    color: #fd5401;
    letter-spacing: 1px;
}

.article-btn {
    margin-bottom: 30px;
}

section.more-article h2 {
    font-weight: 800;
    font-size: 40px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-top: 20px;
}

.article-content h5 {
    color: #fc5400;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}

.article-content h4 {
    font-weight: 600;
    font-size: 30px;
    color: #0f2544;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: -10px;
    margin-top: 15px;
}

.article-content p {
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
}

.article-btn1 a {
    text-transform: uppercase;
    font-weight: 600;
    color: #fd5401;
    letter-spacing: 1px;
}

.article-box {
    margin-top: 50px;
}

section.more-article {
    margin-bottom: 80px;
}

.footbal-picks {
    max-width: 470px;
    margin: 0 auto;
}

section.survvivor-strategy-blk.insidescop {
    padding: 60px 0px 0px 0px;
}

section.survvivor-strategy-blk.insidescop .imgblk {
    max-width: 48px;
    margin: auto;
    margin-bottom: 30px;
}

section.circa-part1 {
    background-color: #fff3e9;
    padding: 80px 0px 0px 0px;
}

section.betting-record {
    background-color: #fff3e9;
    padding: 80px 0px 30px 0px ;
}

section.hungry {
    padding: 60px 0px 100px 0px;

}
.continue-shoping a {
    text-decoration: none;
}
.Surviour-btn.checkout-button button {
    background: var(--orange);
    padding: 18px 65px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 17px;
    border-radius: 10px;
    letter-spacing: 2px;
    font-weight: 600;
    width: 100%;
    border: none;
}
td.future-grid {
    text-align: center;
}
.continue-shoping a h4 span {
    padding-left: 10px;
}


.slips11 {
    position: relative;
}
.loack-img1 {
    text-align: center;
    max-width: 50px;
    margin: 0 auto 25px auto;
}

.upgrade-btn {
    text-align: center;
    margin-top: 60px;
}

.upgrade-btn.view-btn {
    margin-top: 0px;
}
section.hungry.see-slips img {
    height: 341px;
    object-fit: cover;
}

/* .divs {
    display: none;
} */

.div1-list1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divs ul li:nth-child(1) {
    background: #f2ffee;
}

.divs ul li:nth-child(2) {
    background: #f6f6f6;
}

.divs ul li:nth-child(3) {
    background: #fff;
}

.divs ul li h4 {
    font-size: 18px;
    letter-spacing: 1px;
    color: #085207;
    margin-bottom: 0px;
    font-weight: 600;
}

.divs ul li {
    margin-top: 30px;
    padding: 10px 30px;
    box-shadow: 0px 0px 3px 0px #d6d6d6;
}

div.divs h5 {
    color: #fc5400;
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}

.icon-part img {
    width: 11px;
}

.divs ul {
    list-style: none;
    padding-left: 0px;
}



.load-morebtn {
    text-align: center;
    margin-top: 50px;
}

/*wade kitchen css end*/

/*adam bakery css start*/
section.adam-bakery {
    background: url(../../public/images/adambakeryback.jpg);
    background-size: cover;
    padding: 100px 0px 10px 0px;
    background-repeat: no-repeat;
}

.adam-bakery-total h5 {
    text-transform: uppercase;
    font-family: 'Lato';
    color: #fc5400;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 2px
}

.bakery-box {
    border-radius: 0px 0px 15px 15px;
    background: #fff;
    box-shadow: 0px 0px 18px 1px #d0450017;
    margin-top: 10px;
    padding: 30px 20px;
}

.adam-bakerypart {
    max-width: 1050px;
    margin: 0 auto;
}

.adam-bakerypart img {
    width: 480px;
}

.adam-bakery-total {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.adam-latest {
    padding-top: 60px;
}

.adem-latest-heading h2 {
    font-weight: 800 !important;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px
}

.adem-latest-heading h5 {
    color: #0f2544;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.adem-latest-heading {
    text-align: center;
}

.adam-bakery-list ul li {
    padding: 10px 30px;
    margin-top: 30px;
    padding: 15px 30px;
    box-shadow: 0px 0px 3px 0px #09090917;
}

.adam-bakery-list ul {
    list-style: none;
    padding-left: 0px;
}

.adam-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adam-list-left {
    display: grid;
    width: 75%;
    grid-template-columns: 25% 75%;
}

.adam-bakery-list ul li h4 {
    font-size: 20px;
    letter-spacing: 1px;
    color: #085207;
    margin-bottom: 0px;
    font-weight: bold;
}

.adam-bakery-list ul li:nth-child(1) {
    background-color: #f2ffee;
}

.adam-bakery-list ul li:nth-child(2) {
    background-color: #f6f6f6;
}

.adam-bakery-list ul li {
    background-color: #fff;
}

.adam-bakery-list {
    margin-top: 70px;
}

.bakery-box h2 {
    font-weight: 800;
    font-size: 70px;
    letter-spacing: 3px;
    font-family: 'Lato';
    color: #001adb;
}

.bakery-box h6 {
    color: #001adb;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 600;
}

section.future-bets {
    background: #fff4eb;
    PADDING: 15PX 0PX 30px 0px;
}

section.future-bets {
    background: #fff4eaf2;
    PADDING: 15PX 0PX 70px 0px;
}

.adam-slips img {
    width: 100%;
}




.adam-slips:after {
    content: "";
    background: rgb(255 255 255 / 26%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.slips-heading {
    margin-bottom: 50px;
}
.upgrade-btn.view-btn {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 9;
}

.video-wrapper::before {
    content: '';
    width: 35px;
    height: 35px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnCAYAAACMo1E1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIbSURBVHgB1ZiPVcIwEId/5TkAI9QJLBNQJpANeE6gToBOABvABuAEhQmACdoR2OC8I6mvRpDmT4N+7x0t0LQfyTU5msABIurzZszxwJFzyPu0cUilY8+xlW2SJBW6hKVyjoLcWEl7hMZTyqTkyOALn6TPMadumJFKDyexVP/KLinlOjZescTsBSOL/SqYGGKSAzt8nxZiUXEMeMo51h/0jAPecBsx6OtOz37DvZZZDMOCY0rdkJ+TKy1OMNFtJD8XFJbCFMvt2iu5Rvshhb2JTr1X59wzPOAk3nLc8+4TVGL7onKP1NDYMrl0Vn2+GfnTl57zX+caSPXB8cq70pNLuPMockN0gJaUYXYd6ix4z5mw4FLno/RmZdE0F7kUEWDBOW9GaD/UfZAbE3igb5rdtYv0cAN0Pg6gyviL3ESOdGWNK/kuchUiQaqyXvCuiOVXDq/u+OWICFK8eYFaidqW5ie5DTqcTkitk9JbKezYy7Ae0AGkSjAZPokU9mxEbo2A6LySOU0q6hzuHHq6LN4gACwleVXCs8ph1l9PCMi/npP2JYVjaP7qwqJxsxIuKCxl7XTX8HtH+xyRZJfFfIrwjM9+SmGKRB9mTZ//879VfzFCxCVNU8l1m2IXob/6rCSyYGkt1hCU2b6rm8T9+ZwhmVG4XizInGRDQOqf/YrcKGylEjhAKk/qJ+lSbqX4+TS9XrOlFP9odScafAI5lYN1ls1p0gAAAABJRU5ErkJggg==) no-repeat 0 0 / cover;
    position: absolute;
    z-index: 2;
    top: 45%;
    left: 44%;
    transform: translateY(-50%);
}

section.adam-inside {
    background: #fff3e9;
    padding: 60px 0px;
}



section.adam-inside .adem-latest-heading p {
    color: #0f2544;
    letter-spacing: 1px;
    max-width: 750px;
    margin: 0 auto;
}

.adam-inside-video img {
    width: 100%;
}

.adam-inside-video {
    margin-top: 50px;
    position: relative;
}

.loack-img1 img {
    max-width: 50px;
}

.video-title-inside p {
    margin-bottom: 0px;
    color: #fff;
    font-size: 22px;
    letter-spacing: 1px;
    font-family: lato;
}

.video-title-inside {
    position: absolute;
    bottom: 40px;
    left: 30px;
}

.adam-iside-slider {
    margin-top: 30px;
}

.video-title-inside1 p.inside-title {
    margin-bottom: 0px;
    color: #0f2544;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: lato;
}

.video-title-inside1 {
    margin-top: 20px;
}


#inside-slider .owl-prev,
.owl-next {
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    background-color: rgb(0 0 0 / 0%);
    color: #fc5400;
    padding: 10px;
    border-radius: 50%;
    font-size: 66px;
    cursor: pointer;
}

.owl-theme .owl-nav {
    background: #86979100;
    color: #fc5400;
    text-decoration: none;
    font-size: 66px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #86979100;
    color: #fc5400;
    text-decoration: none;
}

#inside-slider .owl-prev {
    left: -30px;
    /* Position the left arrow */
}

#inside-slider .owl-next {
    right: -30px;
    /* Position the right arrow */
}

#inside-slider .owl-prev:hover,
.owl-next:hover {
    background-color: rgb(0 0 0 / 0%);
}

#inside-slider p.inside-date {
    color: #0f2544;
    font-family: lato;
}

.circaselect-box.jumpdate {
    display: none;
}

/*adam bakery css end*/

section.signup-form {
    background: url(../../public/images/signup.jpg);
    background-size: 100%;
    padding: 70px 0px 10px 0px;
    background-repeat: no-repeat;
    background-position: bottom;
}
section.login-part{
    background: url(../../public/images/signup.jpg);
    background-size: cover;
    padding: 50px 0px 10px 0px;
    background-repeat: no-repeat;
}
.signup-form-sub {
    max-width: 650px;
    margin: 70px auto;
}
.signup-form h2 {
    color: #001adb;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;   
}
.login-part .signup-form {
    max-width: 650px;
    margin: 70px auto;
    position: relative;
}
.login-part .signup-form input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
    border: 1.5px solid #fc5400;
    padding: 15px 15px;
    border-radius: 10px;
    margin-top: 70px;
}

.signup-form-sub input {
    width: 100%;
    margin-bottom: 10px;
    border: 1.5px solid #fc5400;
    padding: 15px 15px;
    border-radius: 10px;
}
.signup-form-sub input:-webkit-autofill {
    background-color: white !important; /* Or any preferred color */
    -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Remove the gray autofill shadow */
    box-shadow: 0 0 0px 1000px white inset !important;
}
.login-part .signup-form input[type="submit"] {
    position: absolute;
    top: 101px;
    background: #fc5400;
    padding: 16px 50px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 0px 8px 8px 0px;
    letter-spacing: 1px;
    font-weight: 600;
    max-width: 200px;
    position: absolute;
    right: 0;
    border: none;
}
.login-part p a {
    background: #ffffff00;
    padding: 10px 0px;
    border-radius: 4px;
    color: #fc5400;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: underline;
}
.signup-form-sub input::placeholder {
    color: #b1b1b1;
    font-weight: 600;
    letter-spacing: 1px;
}
.contact-form textarea::placeholder {
    color: #b1b1b1;
    font-weight: 600;
    letter-spacing: 1px;
}


.signup-form-sub h5 {
    text-align: center;
    font-size: 16px;
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 30px;
}

.Surviour-btn.signin-btn a {
    padding: 18px 70px;
}

.signin-btn {
    text-align: center;
    margin-top: 30px;
}
.signup-form-sub a {
    color: #f00;
    letter-spacing: 1px;
    font-weight: 600;
}
.commonTwoBlk {
    background: #fff;
    border-radius: 12px;
    padding: 50px 15px 50px 15px;
    text-align: center;
    box-shadow: 0 0 9px 3px rgb(222 222 222 / 40%);
}

._heading {
    color: #001adb;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
}
a.sign-up1 {
    color: #fc5400;
    text-decoration: underline;
    letter-spacing: 2px;
    font-weight: 600;
}

._blk {
    margin-top: 25px;
}

._blk .label {
    margin-bottom: 0;
    color: #fc5400;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 2px;
}

._blk p {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 2px;
}

.btn-fill {
    margin-top: 20px;
    width: 280px;
    background: #fc5400;
    padding: 18px 0px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 600;
    border: 0px;
}

.user-photo {
    position: relative;
    margin-top: -120px;
    margin-bottom: 10px;
}

.upload_blk h2 {
    color: #001adb;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-top: 20px;
    font-family: 'Poppins';
    text-transform: capitalize;
}

.upload_blk p {
    font-size: 12px;
    letter-spacing: 1px;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
}

.inputfile-2+label span {
    color: #fc5400;
    border-bottom: 1px solid #fc5400;
}

.inputfile-2+label img {
    max-width: 100%;
    width: 12px;
    margin-top: -4px;
}

.anchor {
    color: #001adb;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;

}

.upload_blk {
    margin-top: 100px;
}
.Surviour-btn.signin-btn input[type="submit"] {
    background: #fc5400;
    padding: 15px 50px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 600;
    max-width: 200px;
}
/*ccheckoutpagecss*/
.checkout-page{
    background: url(../../public/images/checkoutback.png);
    background-size: cover;
    padding: 50px 0px 10px 0px;
    background-repeat: no-repeat;
    
}
.continue-shoping h4 {
    text-transform: uppercase;
    font-family: 'Lato';
    color: #fc5400;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
}
.checkoutdetail {
    margin-top: 40px;
}
.car-heading h2 {
    font-weight: 800;
    font-size: 45px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 20px;
    text-align: center;
}
.left-part-checkout h5 {
    color: #001adb;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;}
    .left-part-checkout p {
        color: #0f2544;
        letter-spacing: 1px;
        margin-top: 10px;
        line-height: 30px;
        font-size: 17px;
    }  
    .tire-membership {
        width: 100%;
        margin-bottom: 10px;
        border: 1.5px solid #fc5400;
        padding: 15px 15px;
        border-radius: 10px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding: 30px 20px;
        align-items: center;
        color: #fc5400;
    }
    .tire-membership h4 {
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 600;
        margin-bottom: 0px;
    }
    .promo-code{
        position: relative;
    }
    .promo-code input[type="text"] {
        width: 100%;
        margin-bottom: 10px;
        border: 1.5px solid #001adb;
        padding: 30px 20px;
        border-radius: 10px;
        font-size: 18px;
    }
    .promo-code input::placeholder {
        color: #001adb;
        font-weight: 600;
        letter-spacing: 1px;
    }
    .bagels-locksimgcheckout {
        text-align: center;
        margin-top: 50px;
    }
    .Surviour-btn.promo-button {
        margin-top: 20px;
    }
    .remove-promo {
        position: absolute;
        right: 15px; /* Adjust this value as needed */
        top: 50%;
        transform: translateY(-100%);   
        cursor: pointer;
        color: red; /* Color for the cross icon */
        font-size: 60px; /* Adjust size as needed */
        font-weight: bold;
        display: flex; /* Align vertically */
        align-items: center; /* Center the cross */
        pointer-events: all; /* Ensure clicks are registered */
    }
    .bagels-locksimgcheckout img {
        max-width: 50%;
        width: 100%;
    }
    .right-part-checkout {
        border-radius: 0px 0px 0px 0px;
        background: #fff;
        box-shadow: 0px 0px 18px 1px #d0450017;
        margin-top: 10px;
        padding: 30px 30px;
        margin-left: 30px;
    }
    .right-part-checkout h5 {
        color: #001adb;
        font-size: 20px;
        letter-spacing: 2px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .right-part-checkout img {
        max-width: 60%;
        width: 100%;
    }
    .checkout-form input {
        width: 100%;
        margin-bottom: 10px;
        border: 1.5px solid #fc5400;
        padding: 15px 15px;
        border-radius: 10px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        align-items: center;
        color: #fc5400;
    }
    .checkout-form {
        margin-top: 30px;
    }
    .line-card {
        border: 1px solid #fc5400;
        margin-bottom: 10px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .total-part table td {
        border-style: hidden !important;
    }
    
    .total-part table {
        width: 100%;
        border: none;
    }
    .total-part table tr td {
        color: #0f2544;
        letter-spacing: 1px;
        margin-top: 10px;
        line-height: 30px;
        font-size: 17px;
    }
    .Surviour-btn.checkout-button a {
        padding: 18px 180px;
    }
    .Surviour-btn.checkout-button {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .checkoutdetail {
        margin-bottom: 50px;
    }
    .right-tier h4 i {
        padding-left: 10px;
    }
    .circalcenter img {
        max-width: 100%;
        width: 100%;
        vertical-align: text-bottom;
        height: 40px;
    }

    .adam-slider-part img {
        max-width: 100%;
    }

    .more-article .article-img1 img {
        height: 210px;
        object-fit: cover;
        width: 100%;
    }
    
    .adam-slips img {
        width: 100%;
        filter: blur(5px);
    }
    .nodata {
    font-family: 'Lato';
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 17px;
    text-align: center;
}
.see-slips .slick-prev:before, .see-slips .slick-next:before {
    color: #fc5400 !important;
    opacity: 5;
}
.see-slips .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054" !important;
    font-size: 30px;
    opacity: 5;
}
.see-slips .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053" !important;
    font-size: 30px;
}

.slips11 .slick-current {
    padding-right: 15px;
}
.images-box img {
    height: 200px;
    object-fit: cover;
}
p.inside-date {
    font-size: 14px;
    margin-top: 9px;
}

.privacy {
    background-color: #fff3e9;
    padding: 50px 0px 50px 0px;
}
.privacy p {
    font-family: 'Lato';
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 15px;
}
.privacy ul li {
    margin-bottom: 10px;
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 15px;
}
.privacy p a {
    font-family: 'Lato';
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 15px;
    font-weight: 600;
}
.privacy h2 {
    font-weight: 600 !important;
    font-size: 21px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 0px;
}
h2.contactheading {
    font-weight: 800;
    font-size: 40px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
}
.box-part.contact-box {
    min-height: auto;
    padding: 40px;
}

.box-part.contact-box i {
    height: 90px;
    width: 90px;
    background: #fc5400;
    line-height: 90px;
    border-radius: 50%;
    font-size: 45px;
    color: #fff;
}
.box-part.contact-box h4 a {
    color: #0f2544;
    text-decoration: none;
    font-family: 'Lato';
    word-wrap: break-word;
}
.box-part.contact-box h4 {
    margin-top: 30px;
}
.signup-form-sub textarea {
    width: 100%;
    margin-bottom: 10px;
    border: 1.5px solid #fc5400;
    padding: 15px 15px;
    border-radius: 10px;
    height: 100px;
}
.signup-form-sub.contact-form input[type="submit"] {
    background: #fc5400;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.contact-detail {
    max-width: 956px;
    margin: 0 auto;
}
.contact-form {
    max-width: 956px;
    margin: 0 auto;
}
.contact-form {
    max-width: 956px;
    margin: 80px auto;
    background: #ffffff;
    padding: 70px;
    margin-top: 50px;
    box-shadow: 0px 0px 4px 0px #0d0c0c0a;
}
.mobile-slideradamkitchen {
    display: none;
}
.circaselect-box.jumpdate.mobile-jump {
    display: none;
}

.commonTwoBlk.upload_blk {
    margin-bottom: 10px;
}
.signup-form .one1 {
    padding-right: 5px !important;
}
.signup-form .one {
    padding-left: 5px !important;
}
.cancel-membership {
    margin-top: 40px;
    margin-bottom: 50px;
}
.circa-heading.circa-top1 h2 {
    text-transform: capitalize;
}
.continue-shoping img {
    width: 16px;
    height: 15px;
    margin-top: -5px;
}
span.continue1 {
    padding-left: 10px;
}
.right-tier img {
    width: 20px;
}
.right-tier h4 span {
    padding-right: 10px;
}
.Surviour-btn.upgrade-btn a {
    padding: 18px 40px;
    letter-spacing: 2px;
    border-radius: 10px;
}
.Surviour-btn.upgrade-btn.grid-btn-circa a {
    padding: 18px 53px;
}
.circa-heading.wade-bakery h2 {
    text-transform: capitalize;
    margin-bottom: 0px;
}
.membership-heading.wade-catch p {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 20px;
}
.membership-heading.gain-access h5 {
    margin-top: 20px;
}
section.hungry.see-slips {
    padding-bottom: 70px !important;
}
section.adam-inside {
    background: #fff3e9;
    padding: 30px 0px 30px 0px;
}
.adam-video {
    margin-top: 40px;
}
section.future-bets.full-betting {
    padding-bottom: 0px;
    overflow: hidden;
    clear: both;
}
h5.fullaccess {
    margin-top: 15px;
}
ul.list11.contact-social-icon {
    justify-content: center;
    list-style: none;
}

section.signup-form.membership-part.contact-page {
    padding-bottom: 0px !important;
    overflow: hidden;
    clear: both;
    padding-top: 50px;
}
section.signup-form.membership-part.contact-page h2 {
    margin-bottom: 40px;
}
.article-box.gridarticle-box {
    margin-top: 30px !important;
}
.membership-heading.unlock p {
    color: #fff;
}
.listenpodcast {
    max-width: 550px;
    margin: 60px auto 0 auto;
}
.listenpodcast button {
    background: transparent !important;
}
.listenpodcast .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054" !important;
    font-size: 30px;
    position: absolute;
    top: 30px;
    opacity: 5;
    right: 100px;
}
.listenpodcast .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053" !important;
    font-size: 30px;
    position: absolute;
    top: 30px;
    opacity: 5;
    left: 120px;
}
.listenpodcast h4 {
    color: #fff;
    margin-top: 10px;
    font-family: lato;
    letter-spacing: 2px;
    margin-bottom: 20px;
}
.listenpodcast .audio-part {
    display: block !important;
}
p.latest-1 {
    color: #fff !important;
}

.listenpodcast audio {
    width: 300px !important;
}
div#div1 {
    margin-top: 60px;
}
.article-content {
    margin-top: 20px;
    /* padding-left: 20px; */
}
.Chnage-password button[type="submit"] {
    background: #fc5400;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 18px 50px;
    margin-top: 15px;
}


@media only screen and (max-width: 768px) {
    .circalcenter {
        display: none;
    }
    .bakery-box {
        border-radius: 0px 0px 30px 30px;}
    .article-part {
        padding: 0px 15px;}
        .article-btn1.read_more {
            margin-bottom: 30px;
        }    
    .wade-date .date-img {
        padding: 24px 0px 15px 0px;
    }
    .survvivor-strategy-blk h3 {
        letter-spacing: 2px;
    }
    p.latest-1 {
        color: #fff !important;
    }
    .article-img {
        right: -30px;
        top: -216px;
    }
    .wade-moreart {
        margin-top: 40px;
    }
    .listenpodcast .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
        opacity: 5;
    }
    .listenpodcast .slick-dots li button:before {
        font-size: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        content: "";
        border: 2px solid #fff;
        border-radius: 100%;
        opacity: 1;
    }
    .listenpodcast .slick-dots li {
        margin: 0 0px !important;
    }
    .box-part.contact-box .footer-left h4 {
        font-size: 20px !important;
        margin-top: 20px;
    }
    .footer-left ul.list11.contact-social-icon li {
        margin: 15px 20px 0px 20px;
    }
    section.signup-form.membership-part.contact-page h2 {
        margin-bottom: 0px;
    }
    section.signup-form.membership-part.contact-page {
        padding-top: 20px;
    }
    .checkout-page{
        background: url(../../public/images/checkoutmob.jpg);
        
    }
    .bagels-locksimgcheckout img {
        max-width: 80%;
    }
    .Surviour-btn.checkout-button {
        margin-bottom: 20px;
    }
    section.adam-inside.adam-inside1 {
        padding-bottom: 0px !important;
    }
    section.future-bets.full-betting h5 {
        padding: 0px 60px;
    }
    .mobile-slideradamkitchen .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
        opacity: 5;
    }
   
    ul.list11.contact-social-icon {
        margin-bottom: 0px;
    }
  
    .adam-bakery-list {
        margin-top: 40px;
    }
    .adam-bakery-total h5 {
        margin-top: 25px;
    }
    .bakery-box1 {
        padding-right: 0;
    }
    .Surviour-btn.load-morebtn.wade-loadmore {
        margin-top: 0px;
    }
   
    section.more-article.wade-article {
        margin-top: 230px;
        margin-bottom: 60px;
    }
    section.circa-part1.foot-pick {
        padding-top: 60px !important;
    }
    section.betting-record {
        padding: 50px 0px 0px 0px;
        overflow: hidden;
        clear: both;
    }
    
    
    .wade-moreart {
        margin-top: 40px;}
        .wade-moreart h2 {
            font-size: 30px !important;
            margin-bottom: 30px;
        }  
    h2.article-headingwade {
        text-align: left !important;
        padding: 0px 0px;
        font-size: 30px !important;
    }
    .circa-heading.Strategy-Hub {
        width: 85%;
        text-align: center;
        margin: 0 auto;
    }

    .outline-btn {
        text-transform: uppercase;
        color: #fc5400;
        font-weight: 800;
        border-radius: 12px;
        font-size: 15px;
        padding: 8px 15px;
        border: 2px solid #fc5400;
        letter-spacing: 2px;
        width: 200px;
    }



    .checkoutdetail {
        margin-bottom: 30px;
    }
    .Surviour-btn.checkout-button button {

        border-radius: 16px;
        width: 70%;
    }
    .right-part-checkout h5 {
        text-align: center;
    }
    .right-part-checkout {
        margin-left: 0px;
        margin-top: 40px;
    }
  
    .tire-membership {
        border: 2px solid #fc5400;}
    .continue-shoping {
        display: none;
    }
    .left-part-checkout {
        text-align: center;
    }
    .circaselect-box {
        width: 260px;}

        .circaselect-box select {
       
            padding: 15px 20px;
            border-radius: 12px;
            border: 2px solid #fc5400;
           
        }

    .circa-part {
        padding: 50px 0px 40px 0px;
    }
    .cancel-membership {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .circalright {
        text-align: right;
    }
    .cicraleftt {
        margin-top: 0px;
        align-items: center;
    }
    .cicraleftt {
        margin-bottom: -5px;
    }
    .survvivor-strategy-blk {
        margin-top: 0px;}
    .signup-form .one1 {
        padding-right: 12px !important;
    }
    .signup-form .one {
        padding-left: 12px !important;
    }
    .account-sub .one1 .commonTwoBlk {
        border-radius: 20px 20px 0px 0px;
    }
    .account-sub .one .commonTwoBlk {
        border-radius: 0px 0px 20px 20px;
        box-shadow: 0 0 9px 3px rgb(222 222 222 / 0%);
        padding-top: 10px;
    }
    
    section.signup-form {
        background: url(../../public/images/accoutmobback.jpg);
        background-size: cover;
        padding: 40px 0px 10px 0px;
        background-position: bottom;
    }
    .commonTwoBlk {
        border-radius: 20px;}



    .circaselect-box.jumpdate.mobile-jump {
        display: block;
    }
    section.adam-bakery {padding: 0px 0px 10px 0px;}
    section.adam-inside {
        background: #fff3e9;
        padding: 30px 0px 50px 0px;
    }
    section.hungry {
        padding: 60px 0px 60px 0px;
    }
    .adam-list-left { grid-template-columns: 100%;}
    .circaselect-box.jumpdate.desktop-jump {
        display: none;
    }
    .adam-left2 {
        margin-top: 10px;
    }
    .video-title-inside1 {
        position: absolute;
        bottom: 0;
        left: 15px;
    }
    .video-title-inside1 p.inside-title {
        color: #fff;
        font-weight: 500;
    }
    .video-title-inside1 p.inside-date {
        font-size: 14px;
        margin-top: 0px;
    }
    .video-title-inside1 p.inside-date {
        color: #fff;
    }
    .mobile-slideradamkitchen {
        display: block;
    }
    .mobile-slideradamkitchen .slick-dots li {
        margin: 0 0px !important;
    }
    .mobile-slideradamkitchen .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
    }
    .mobile-slideradamkitchen .slick-dots li button:before {
        font-size: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        content: "";
        border: 2px solid #000;
        border-radius: 100%;
        opacity: 1;
    }
    .mobile-slideradamkitchen img {
        height: 229px;
        object-fit: cover;
    }
   
  
    
    .container.adam-video {
        display: none;
    }
    section.hungry.see-slips img {
        height: auto;
        object-fit: cover;
    }
    .membership-menu {
        background: #102544;
    }



    .slips11 .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
        opacity: 5;
    }
    .slips11 .slick-dots li {
        margin: 0 0px !important;
    }
    .slips11 .slick-dots li button:before {
        font-size: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        content: "";
        border: 2px solid #000;
        border-radius: 100%;
        opacity: 1;
    }

    .circalleft,
    .circalright {
        width: auto;
    }

    .circalright h3 {
        font-size: 20px;
    }

    /*wadekitchen css start*/
    section.wade-kitchen {
        margin-bottom: 150px;

    }

    .wade-kitchen .article-img {
        top: -216px;
        right: -31px;
    }

    section.wade-kitchen {
        margin-bottom: 30px;
        padding-top: 180px;
    }

    section.wade-kitchen .date-img {
        padding-top: 30px;
    }

    .article-img img {
        width: 100%;
    }

    section.more-article {
        margin-top: 220px;
    }

    section.more-article h2 {
        text-align: center;
    }

    .article-content {
        padding: 20px;
    }

    .circa-box1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .circa-part1 .circa-box {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    section.survvivor-strategy-blk.insidescop {
        margin-top: 0px;
    }
    .article-content h4 {
        font-size: 28px;
    }

    .article-heading h2 {
        font-size: 24px;
    }

    section.more-article h2 {
        font-size: 24px;
    }

    .article-box {
        margin-top: 24px;
    }

    .circa-heading h2 {
        font-size: 40px;
        text-transform: capitalize;
    }
    .survvivor-strategy-blk {
        padding-top: 50px;}
        .outline-btn {
            width: 250px;
            padding: 13px 15px;
        }    
    .circaselect-box {
        margin-top: 45px;
    }

    .grid-postcard-blk {
        margin-top: 35px;
        text-align: center;
        margin-bottom: 50px;
    }
    .grid-postcard-blk .imgblk {
        margin-bottom: 40px;
    }
    .upgrade-btn {
        margin-top: 40px;
    }
    .circa-box {
        margin-top: 50px;
        margin-bottom: 20px;
    }


    /*wade kitchen css end*/

    /*adambakery css start */
    .adam-bakerypart img {
        width: 100%;
        margin-top: -15px;
    }

    .adem-latest-heading h2 {
        font-weight: 800;
        font-size: 37px;
    }

    .adam-list-left {
        width: 100%;
        flex-direction: column;
    }

    .adam-bakery-list ul li h4 {
        font-size: 14px;
    }

    .upgrade-btn.view-btn {
        position: absolute;
        top: 55%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }

    .adam-inside-video {
        display: none;
    }

    .circaselect-box.jumpdate {
        display: block;
    }

    .article-headline-blk .slick-slider {
        top: -15px;}

        .header-part1 {
            position: relative;
            z-index: 1;
        }
        .adam-bakerypart .row .col-md-6:first-child{
            padding-right: 0px;
        }   
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }

}
.out-of-pool-message {
    background-color: #ffcccc;
    color: #cc0000;
    padding: 10px;
    margin: 20px 0;
    text-align: center;
    border: 1px solid #cc0000;
    border-radius: 5px;
  }
  
.survvivor-strategy-blk h3 {
    padding: 0px 17%;
}

section.dashborad {
    background: #fff3e9;
    padding: 60px 0px;
}
.dashborad-name .nav button {
    background-color: #fc5400;
    border-bottom: 1px solid #fff;
    border-radius: 0px;
    text-align: left;
    color: #fff;
    padding: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Lato';
}
.dashborad-name .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #0f2544 !important;
}
.dashboardcontent {
    background-color: #fff;
    padding: 25px;
}
.signup-form-sub.profile-form {
    max-width: 100%;
    margin: 30px 0;
}
.signup-form-sub.profile-form input[type="submit"] {
    background: #fc5400;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.Chnage-password p {
    margin-bottom: 6px;
    margin-top: 0px;
    color: #9d9797;
}
.Chnage-password input[type="submit"]{
    background: #fc5400;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.dashboardcontent h4 {
    font-weight: 600 !important;
    font-size: 21px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-bottom: 15px;
}
.inputDiv {
    max-width: 460px;
}
.inputDiv label {
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
}
.inputDiv input {
    width: 100%;
    margin-bottom: 10px;
    border: 1.5px solid #fc5400;
    padding: 15px 15px;
    border-radius: 10px;
}
.inputDiv {
    max-width: 460px;
    margin-bottom: 5px;
}


/*adambakerycss end*/





@media only screen and (max-width: 480px) {
    .pre-next-button {
        flex-direction: column;
        gap: 15px;
        max-width: 62%;
        margin: auto;
    }
    .adam-latest h5 {
        padding: 0px 50px;
        line-height: 30px;
    }  
    section.betting-record h2 {
        font-size: 40px;
    }
    section.betting-record h5 {
        padding: 0px 13%;
        line-height: 30px;
    }
    .article-headline-blk .slick-slider button.slick-arrow.slick-prev {
        margin-left: 158px !important;}
    .cicracontent h4 {
        font-size: 14px;
    }

    .circalright h3 {
        font-size: 18px;
    }

    .Surviour-btn.signin-btn input[type="submit"] {
        border-radius: 20px;}
  
}
    
/* .promo-code input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
    border: 1.5px solid #001adb;
    padding: 18px 15px;
    border-radius: 10px;
    font-size: 18px;
}
.promo-code input::placeholder {
    color: #001adb;
    font-weight: 600;
    letter-spacing: 1px;
}
.Surviour-btn.promo-button a {
    padding: 18px 180px;
}
.Surviour-btn.promo-button {
    margin-top: 20px;
    margin-bottom: 20px;
} */