:root {
    --white: #ffffff;
    --black: #000000;
    --orange: #fc5400;
    --dark-blue: #001adb;
    --lightblue: #e2f7ff;
    --black2: #101113;
}

body {
    font-family: "lato", sans-serif;
    overflow-x: hidden;

}

p {
    color: #0f2544;
}

.header-part {
    background: var(--black2);
}

.top-left h2 {
    color: #9c9898;
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 300;
    letter-spacing: 1px;
}

.header-part {
    background: var(--orange);
    padding: 10px 0px;
    text-align: center;
}

.header-part p {
    margin-bottom: 0px;
    color: #fff;
    letter-spacing: 2px;
    font-family: lato;
}

.main-header {
    background: #0f2544;
    padding: 25px 0px;
    border-top: 1px solid #0f2544;
}

.search-box input[type="search"] {
    color: #fff;
    background-color: #2B2E34;
    border: none;
    border-radius: 25px;
    padding: 4px 30px;
    font-size: 12px;
    width: 350px;
    height: 34px;
}

.search-box {
    position: relative;
}

.search-box>img {
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}

.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-btn {
    margin-right: 53px;
}

.logo-left img {
    width: 125px;
}

.menu-center ul {
    display: flex;
    padding-left: 0px;
    margin-bottom: 0px;
    list-style: none;
}

.sign-right {
    display: flex;
    align-items: center;
    padding-right: 25px;
}

.video-part .slick-slide {
    padding: 0 5px;
}

.video-part .slick-slide img {
    display: block;
    max-width: 100%;
    width: 100%;
    position: relative;
}
.video-wrapper::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgb(255 255 255 / 0%) 50%, rgb(16 37 68) 100%);
    position: absolute;
}
.menu-center ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'Lato';
    letter-spacing: 2px;
}

.sign-right img {
    width: 22px;
}

.account1 {
    margin: 0px 20px;
}

.menu-center>ul>li {
    padding: 0px 8px;
}



.Signup {
    margin-left: 0px;

}

.menu-center ul li a:hover {
    color: var(--orange);
}

.cart-img {
    position: relative;
  }
  
  .cart-count {
    position: absolute;
    top: -4px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background: rgb(219, 10, 10); /* Background color */
    color: white; /* Text color */
    border-radius: 50%;
    padding: 2px 7px;
    font-size: 12px; /* Font size */
  }  
  
.Signup a {
    background: var(--dark-blue);
    padding: 10px 30px;
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    font-size: 17px;
}



.cart-img {
    padding-right: 25px;
}

.card-header {
    padding: 15px;
    background-color: var(--dark-blue);
    border-radius: 15px 15px 0 0;
    align-items: start;
}

.card-header h3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    color: var(--white)
}

.slider-content-container {
    padding: 35px 35px 49px;
    background-color: #15171a;
    border-radius: 0 0 15px 15px;
}

.headshot-container {
    text-transform: uppercase;
    margin-bottom: 35px;
}

.slider-content-container .full-description {
    font-size: 14px;
    margin-bottom: 35px;
    min-height: 190px;
}

.slider-content-container .cart-buttons-container {
    border-top: 1px solid #686F78;
    border-bottom: 1px solid #686F78;
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
}

.slider-content-container .user-short-description h2 {
    font-size: 34px;
    font-weight: 300;
    color: var(--orange);
    margin-bottom: 15px;
    text-decoration: none;
}

.slider-content-container .user-short-description h6,
.slider-content-container .user-short-description h5 {
    font-size: 16px;
    font-weight: 700;
    color: #bfc4e4;
    text-decoration: none;
}

.user-short-description a {
    text-decoration: none;
}

.slider-content-container .cart-buttons-container .primary {
    background-color: var(--dark-blue);
}

.slider-content-container .cart-buttons-container .btn {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.slider-content-container .cart-buttons-container .secondary {
    background-color: #F48120;
}

.full-description p {
    color: #fff;
}

.headshot-image {
    width: 190px !important;
    height: 190px !important;
    border: 6px solid #32383F;
    border-radius: 200px;
    margin-right: 15px;
}

.tabs-content-container {
    padding-top: 10px;
}

.tabs-content-container ul#pills-tab {

    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px !important;
    padding-bottom: 10px;
    padding-top: 10px;
}

.tabs-container .tabs-nav-wrapper:after {
    content: '';
    width: 45px;
    height: 100%;
    position: absolute;
    border-radius: 0;
    background: linear-gradient(268deg, #0a0b0c, #0a0b0c96, transparent);
    z-index: 2;
    top: 0;
    right: -2px;
}

.tabs-container .nav-link,
.what-pros-say-section .nav-link,
.sticky a.nav-link {
    color: #fff;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    font-weight: bold;
    text-transform: uppercase;
    border: 2px solid #686F78;
    margin-right: 0.75em;
    padding: 10px 30px;
}

.tabs-container .nav-link.active,
.what-pros-say-section .nav-link.active {
    background-color: #f48120;
    border: 2px solid #f48120;
}

.tab-content-heading.d-grid h3:nth-child(2) {
    padding-left: 14px;
}

.video-wrapper::before {
    content: '';
    width: 35px;
    height: 35px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnCAYAAACMo1E1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIbSURBVHgB1ZiPVcIwEId/5TkAI9QJLBNQJpANeE6gToBOABvABuAEhQmACdoR2OC8I6mvRpDmT4N+7x0t0LQfyTU5msABIurzZszxwJFzyPu0cUilY8+xlW2SJBW6hKVyjoLcWEl7hMZTyqTkyOALn6TPMadumJFKDyexVP/KLinlOjZescTsBSOL/SqYGGKSAzt8nxZiUXEMeMo51h/0jAPecBsx6OtOz37DvZZZDMOCY0rdkJ+TKy1OMNFtJD8XFJbCFMvt2iu5Rvshhb2JTr1X59wzPOAk3nLc8+4TVGL7onKP1NDYMrl0Vn2+GfnTl57zX+caSPXB8cq70pNLuPMockN0gJaUYXYd6ix4z5mw4FLno/RmZdE0F7kUEWDBOW9GaD/UfZAbE3igb5rdtYv0cAN0Pg6gyviL3ESOdGWNK/kuchUiQaqyXvCuiOVXDq/u+OWICFK8eYFaidqW5ie5DTqcTkitk9JbKezYy7Ae0AGkSjAZPokU9mxEbo2A6LySOU0q6hzuHHq6LN4gACwleVXCs8ph1l9PCMi/npP2JYVjaP7qwqJxsxIuKCxl7XTX8HtH+xyRZJfFfIrwjM9+SmGKRB9mTZ//879VfzFCxCVNU8l1m2IXob/6rCSyYGkt1hCU2b6rm8T9+ZwhmVG4XizInGRDQOqf/YrcKGylEjhAKk/qJ+lSbqX4+TS9XrOlFP9odScafAI5lYN1ls1p0gAAAABJRU5ErkJggg==) no-repeat 0 0 / cover;
    position: absolute;
    z-index: 2;
    top: 45%;
    left: 44%;
    transform: translateY(-50%);
}

.video-part11 img {
    width: 100%;
}

.modal-dialog.dialog1 {
    max-width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.video-heading {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

section.hero-section h3,
.offers-section h3,
.card-header h3,
.card-header h2 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.tab-content-heading.d-grid {
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin-bottom: 16px;
}

.video-heading h2 {
    color: var(--orange);
}

.video-heading p {
    color: #ddd;
}

section.video-shows img {
    width: 100%;
}

.tab-content-heading h3 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--orange);
}

.tab-content-main.d-grid {
    grid-template-columns: 1fr 4fr;
    align-items: center;
}

.content-list-grid.d-grid {
    grid-template-columns: 2fr 1fr 1fr;
    padding: 8px 14px;
    border: 1px solid #2B2E34;
    background-color: #101113;
    margin-bottom: 4px;
    border-radius: 12px;
    font-size: 16px;
}

.tab-content-main h3.main-content-heading {
    font-size: 18px;
    grid-row: span 3;
    align-self: start;
    color: #F48120;
    padding-top: 0.5rem;
    margin-top: 0 !important;
}

.content-list-grid.d-grid .content-title a {
    color: #85b8ff;
    text-decoration: none;
}

.content-list-grid.d-grid .content-amount {
    font-weight: bold;
    color: #fff;
}

.content-percentage {
    color: #fff;
}

.who-is-hot-coming-soon {
    color: #F48120;
}

.tabs-content-container .tab-content {
    background-color: #15171A;
    padding: 24px;
    border-radius: 0 0 15px 15px;
}

section.slider-tabs {
    padding-top: 100px;
    background: #0f2544;
    padding-bottom: 50px;
}

p.video-title {
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    margin-bottom: 20px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 20px;
    z-index: 9;
}


.tab {
    float: left;
    /* border: 1px solid #000; */
    background-color: var(--orange);
    width: 7%;

    position: sticky;
}

/* Style the buttons inside the tab */
.tab button {
    display: block;
    background-color: inherit;
    color: #fff;
    padding: 15px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 15px;
    border-bottom: 1px solid #e9c194;
    letter-spacing: 1px;
}

section.custumtab {
    margin-top: 0px;
    overflow: hidden;
    padding: 100px 0px;
    background: #fff;
}

/* ul#myTab {
    margin-left: 40px;
} */

.aBT {
    overflow: hidden;
    clear: both;
    background: #141313;
    padding: 60px 0px 80px 0px;
    background: url(/public/images/abtback.jpg);
    position: relative;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.aBT p {
    position: relative;
}

.aBT h2 {
    position: relative;
}

.aBT img {
    position: relative;
}

.aBT:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #000000b8;
}

.aBT p {
    color: #fff;
    font-size: 15px;
    line-height: 23px;
}

.aBT h2 {
    color: var(--orange);
    margin-bottom: 20px;
}

section.top-sport {
    border-bottom: 2px solid #333;
    padding: 60px 0px;
    background: var(--dark-blue);
}

section.top-sport {
    border-bottom: 2px solid #333;
    border-top: 3px solid var(--orange);
}

.footer-content {
    text-align: center;
}

.list-menu ul {
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    flex-direction: column;
    text-align: right;
}

.list-menu ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-left h4 {
    color: #fff;
    font-family: poppins;
    font-weight: 600;
    font-size: 29px;
    letter-spacing: 1px;
}

ul.list11 li img {
    width: 42px;
}

ul.list11 li {
    margin-right: 20px;
    margin-top: 30px;
}

ul.list11 {
    padding-left: 0px;
    display: flex;
    margin-bottom: 0px;
    align-items: center;
}

.list-menu ul li {
    margin: 0px 10px;
}

section.footer {
    padding: 50px 0px 0px 0px;
    background-color: #0f2544;
}

.footer-content img {
    margin-bottom: 20px;
}

.list-menu {
    margin-top: 20px;
}

.copyright1 {
    padding: 20px 0px;
    border-top: 1px solid #fff;
    margin-top: 20px;
}

.copyright1 p {
    margin-bottom: 0px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    font-family: 'Lato';
    letter-spacing: 1px;
}

.top-left ul li i {
    background: var(--dark-blue);
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    color: #fff;
}

.top-left ul li {
    margin: 0px 3px;
}

.top-left ul {
    display: flex;
    margin-bottom: 0px;
    list-style: none;
}

section.top-sport h5 {
    text-align: center;
    color: var(--white);
    font-size: 26px;
}

ul.cappers-links {
    list-style: none;
}

ul.cappers-links li {
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 4px 8px;
}

ul.cappers-links li a {
    color: var(--white);
}

ul.cappers-links {
    list-style: none;
    text-align: center;
    margin-top: 30px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: var(--dark-blue);
    color: #fff;
}

/* Create an active/current "tab button" class */
.tab button.active {
    background-color: #0f2544;
    color: #fff;
}

/* Style the tab content */
.tabcontent {
    float: left;
    padding: 0px 12px;
    border: 1px solid #0f2544;
    width: 93%;
    border-left: none;
    height: auto;
    background: #0f2544;
    padding: 20px;
}

.lorem1 {
    overflow: hidden;
    clear: both;
}

button.odd {
    background: #fc5400;
    color: #fff;
    padding: 13px 30px;
    border: none;
    border-radius: 8px;
    margin-bottom: 30px;
    font-weight: 600;
    letter-spacing: 1px;
}

.select-box {
    text-align: right;

}

.select-box select {
    background: #fff;
    color: #000;
    padding: 11px 10px;
    border: none;
    border-radius: 4px;
    margin-bottom: 30px;
    width: 250px;
    font-weight: 600;
    letter-spacing: 1px;
}

table tr td {
    border: 1px solid #383636;
    padding: 10px;
}

.table11 h4 {
    color: #fff;
    margin-top: 20px;
    font-size: 18px;
}

.table11 h5 {
    color: #fff;
    font-size: 14px;
}

.table11 h5 i {
    color: #d8d816;
}

.tabcontent table tr td p {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}

.right-top .slick-next {
    margin-right: 20px !important;
}

.tabcontent table tr td {
    color: #fff;
    font-size: 14px;
}

.table11 p {
    color: #fff;
}

button.number-btn {
    background: #363739;
    border: none;
    color: #fff;
    padding: 5px 10px;

}

button.buttonnew1 {
    background: #fc5400;
    border: none;
    color: #fff;
    padding: 5px 10px;
}

.left-profile img {
    outline: 1px solid #d1d1d163;
    /* outline-offset: 3px; */
    width: 278px;
}

.profilemiddle {
    padding-right: 25px;
    top: -45px;
    z-index: 1;
    position: relative;
    text-align: right;
}

.profile-last{
    position: relative;
    top: -65px;
    margin-bottom: -55px;
}

section.slider-tabs.about-page {
    background-color: #fff3e9;
    padding: 100px 0px;
}

section.slider-tabs.about-page .Meet h2 {
    font-weight: 800;
    font-size: 50px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
}



.tabcontent td {
    border: 0px;
}

.images-box {
    padding: 5px;
}



.images-part img {
    width: 55px;
    margin-right: 21px;
}

.content-part h4 {
    margin-bottom: 0px;
    font-size: 20px;
}

.table-blk p {
    margin-bottom: 0px;
}

.handicappers-links-section h5.handicappers-title,
.links-wrapper h5 {
    color: #F48120;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.table23 {
    border-bottom: 1px solid rgb(71 69 69);
    padding: 10px 10px 30px 10px;
}

div.logo-slide img {
    filter: brightness(0) invert(1);
}

.table11 {
    margin-top: 10px;
}

.sports-links li.sport-item {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
}

footer ul li a {
    color: #ddd;
    list-style: none;
    text-decoration: none;
}

footer ul li a:hover {
    color: var(--orange);
}

footer ul li {
    list-style: none;
}

.socials-wrapper ul li i {
    background: #ddd;
    width: 40px;
    height: 40px;
    background: var(--orange);
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-radius: 50%;
}

.socials-wrapper ul li i:hover {
    background-color: var(--dark-blue);

}

.socials-wrapper ul li {
    display: inline-block;
    width: 67px;
}

p.disclaimer a {
    color: #ddd;
    text-decoration: none;
}

p.disclaimer {
    color: #ddd;
    margin-bottom: 0px;
}

.fixed-header {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 9 !important;
    transition: all 500ms ease;
    top: 0;
    box-shadow: 0px 0px 2px 2px var(--dark-blue);
    padding: 7px 0px !important;
}



/*table css*/
div.logo-slide img {
    width: 100px !important;
    object-fit: cover;
}

.left-top {
    display: flex;

}

.content-part p {
    color: #fff;
}

.content-part h4 {
    color: #fff;
}

.content-part:before {
    position: absolute;
    content: "";
    left: -11px;
    width: 1px;
    height: 48px;
    background: #ddd;
}

.content-part {
    position: relative;
}

div.logo-slide a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

.logo-slide span {
    width: 20px !important;
    color: var(--dark-blue) !important;
    font-size: 30px !important;
}

.logo-slide button.owl-prev {
    position: absolute !important;
    left: -40px !important;
    top: -15% !important;
}

.logo-slide button.owl-next {
    position: absolute;
    right: 0;
    top: -8px;
}

button.owl-prev {
    color: var(--dark-blue) !important;
    font-size: 30px !important;
}

button.owl-next {
    color: var(--dark-blue) !important;
    font-size: 30px !important;
}

.aBT ul li i {
    position: absolute;
    left: 0;
    top: 4px;
    color: var(--orange);
}

.aBT ul li {
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
    position: relative;
    padding-left: 30px;
}

.aBT ul {
    list-style: none;
    padding-left: 0px;
}

.right-top img {
    width: 100px;
    object-fit: cover;
}

.right-top img {
    filter: brightness(0) invert(1);
}

.right-top a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    margin-left: 20px;
}

.right-top button {
    margin-top: -5px;
}

.table-blk1 table tr td:nth-child(7) {
    display: none;
}

.table-blk1 table tr td:nth-child(6) {
    display: none;
}

.table-blk1 tr td {
    width: 20%;

}

.table-blk1 tr td button {
    width: 100px;
}

.rating {
    padding-top: 20px;
}

/* .left-profile {
    width: 70px;
} */

.progile-img {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
}

.progile-img11 {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.progile-img13 {
    margin-top: 40px;
    position: relative;
    z-index: 2;
}


/* Add this CSS to your stylesheet */
.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.popup .popup-content {
    margin: auto;
    display: block;
    max-width: 90%;
    max-height: 90%;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.progile-img {
    display: flex;
    justify-content: space-between;
}


.progile-img11 {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

section.custumtab ul li button {
    background-color: #0f2544 !important;
    border-radius: 2px !important;
    padding: 11px 25px;
    color: #fff !important;
    border-radius: 8px !important;
    font-weight: 600;
    letter-spacing: 1px;
}

section.custumtab ul.nav li {
    margin: 0px 17px 0px 0px;
}

section.custumtab ul.nav {
    border-bottom: 1px solid transparent;
}


.rating-up:hover {
    transition: 0.8s;
    box-shadow: 0px 20px 60px 0px rgb(98 98 98 / 16%);
    transform: translateY(-10px);
}

/* div#odds-api {
    margin-left: 120px;
} */

/* div#odds-api {
    max-width: 1170px;
    margin: 0 30px 0 40px;
} */

/* div#score select {
    margin-left: 40px;
    padding: 10px 10px;
    width: 130px;
} */
/* 
div#score select {
    margin-left: 40px;
    padding: 10px 10px;
    width: 230px;
    word-wrap: normal;
    padding: 12px 20px;
    border-radius: 10px;
    border: 1.5px solid #fc5400;
    color: #fc5400;
    font-weight: 600;
    letter-spacing: 1px;
    appearance: none;
    text-align: left;
    background-color: white;
    appearance: none;
    position: relative;
} */

/* div#score select:after {
    content: "";
    background-image: url(../../public/images/down-arrow.png);
    position: absolute;
    width: 18px;
    display: block;
    top: 0;
    right: 18px;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    pointer-events: none;
} */


a.Signup {
    margin-left: 8px;
    background: var(--dark-blue);
}

ul.dropdown-menu {
    display: none !important;
}

.menu-center ul li:hover ul {
    display: block !important;
}

ul.dropdown-menu li {
    border-bottom: 1px solid #eb9062;
    padding: 10px 0px !important;
}

ul.dropdown-menu {
    background: #fc5400 !important;
    border-radius: 0px !important;
    padding: 0px !important;
    width: 210px;
    text-align: center;
}

/* .menu-center ul li ul li:hover {
    background: #0f2544;
} */

.table-blk1 {
    margin-bottom: 21px;
}

.menu-center ul li ul li a {
    color: #fff !important;
    font-size: 14px !important;
}

.menu-center ul li ul li {
    padding: 12px 10px !important;
}

.main-banner img {
    width: 100%;
}

.banner-content h2 {
    font-size: 68px;
    font-weight: 800;
    color: #fff;
    letter-spacing: 2px;
}

.banner-content {
    position: absolute;
    top: 50%;
    /* transform: translate(-50%, -50%); */
    max-width: 530px;
    /* left: 29%; */
    transform: translateY(-50%);
    /* width: 50%; */
    left: 10%;
}

/* .banner-content {
    left: 10px;
    right: 10px;
    max-width: 100%;
    transform: translate(0%);
    text-align: center;
}

.banner-content h2 {
    font-size: 40px;
} */

.main-banner {
    position: relative;
}

.learn-btn a {
    background: var(--orange);
    padding: 20px 65px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 17px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 600;
}

.learn-btn {
    margin-top: 30px;
    margin-bottom: 16px;
}

.background-image {
    width: 100%;
    background-image: url('../../public/images/back11.jpg');
    background-size: cover;
    background-position: 100%;
    padding: 60px 0px;
}

.background-image.video-part {
    background: url('../../public/images/back22.jpg');
    background-size: 100%;
    padding-top: 100px;
    background-repeat: no-repeat
}

.background-image.video-part .heading-serving1 {
    margin-bottom: 80px;
}

.video-part .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054" !important;
    font-size: 30px;
}

.video-part .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053" !important;
    font-size: 30px;
}

#odds-api .slick-next:before {
    font-family: 'FontAwesome' !important;
    content: "\f054" !important;
    font-size: 25px;
    color: #fc5400 !important;
    opacity: 5;
}

#odds-api .slick-prev:before {
    font-family: 'FontAwesome' !important;
    content: "\f053" !important;
    font-size: 25px;
    color: #fc5400 !important;
    opacity: 5;
}

.table-blk1 tr td {
    background-color: #fff3e9;
}

.table-blk {
    background: #fff3e9;
}

.heading-serving1 h5 {
    color: #001adb;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
}

.heading-serving1 h2 {
    font-weight: 800;
    font-size: 42px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
    margin-top: 15px;
}

.heading-serving1 {
    text-align: center;
    /* margin-bottom: 32px; */
}
.serving-box{
    margin-bottom: 50px;
    display: inline-block;
}
.box-part {
    background: #ffffff;
    padding: 70px 70px 35px;
    margin-top: 25px;
    box-shadow: 0px 0px 4px 0px #0d0c0c0a;
    min-height: 100%;
    text-align: center;
   
}

.box-part h4 {
    font-weight: 700;
    font-size: 24px;
    color: #fc5400;
    font-family: 'Lato';
    letter-spacing: 2px;
}

.box-part p {
    color: #0f2544;
    letter-spacing: 1px;
    margin-top: 20px;
    line-height: 30px;
    font-size: 17px;
  
}

.learn-btn.learnbtn1 {
    text-align: center;
}

.Meet h2 {
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    font-family: 'Lato';
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 90px;
}

.profile-box {
    background: #ffffff;
    border: 4px solid #fc5400;
    padding: 20px 25px;
    padding-bottom: 40px;
    height: 100%;
}

.person-name1 {
    position: relative;
}

.person-name1 img {
    position: absolute;
    top: -89px;
    height: 330px;
}

.name-part img {
    width: 100%;
    max-width: 200px;
}

ul.lsit-part li i {
    color: #fc5400;
}

ul.lsit-part {
    margin-top: 30px;
    list-style: none;
    padding-left: 0px;
}

ul.lsit-part li {
    margin-bottom: 10px;
    color: #0f2544;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.profile-content {
    padding-top: 30px;
    text-align: left;
    /* padding-left: 25px;
    padding-right: 25px; */
}

.left-profile.profilewade img {
    width: 100%;
    /* height: 376px; */
    object-fit: cover;
}

/* .left-profile.profilewade {
    width: 470px !important;
} */

.profile-content p {
    font-size: 14px;
    letter-spacing: 1px;
    color: #0f2544;
    font-family: 'Lato';
    line-height: 25px;
}

.footerlogo {
    text-align: center;
}

.footerlogo img {
    width: 160px;
}

.list-menu li a {
    font-weight: 600;
    font-size: 18px;
    font-family: 'Lato';
    letter-spacing: 1px;
}

.list-menu ul li {
    margin-bottom: 20px;
}

.list-menu {
    margin-top: 20px;
}



img.twitter1 {
    width: 30px !important;
}

.menu-center {
    display: flex;
}

.click {
    display: none;
}

.video-part .slick-prev,
.video-part .slick-next {
    top: 50% !important;
}

.Meet a {
    text-decoration: none;
}


.mobileview {
    display: none;
}

.background-image.serving-mobile {
    display: none;


}

.mobile-footer {
    display: none;
}

.account1 {
    margin: 0px 20px;
    position: relative;
    color: #fff;
}

.account1 ul li {
    text-transform: uppercase;
}

.account1 ul li div {
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 11px 0px;
}

.username {
    border-bottom: 1px solid #e79167;
}

.account1 ul {
    position: absolute;
    background: #fc5400;
    width: 150px;
    left: -66px;
    text-align: center;
    margin: 0 auto;
    top: 27px;
    z-index: 9;
    display: none;
    border-radius: 4px;
}

.account1:hover ul {
    display: block !important;
}

.account1 ul li:hover a {
    color: #fff !important;
}

.menu-center ul li button {
    background: transparent;
    border: none;
}

.menu-center ul li button {
    background: transparent;
    border: none;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'Lato';
    letter-spacing: 2px;
}

.menu-center ul li button:hover {
    color: var(--orange);
}

.bed-slip {
    display: none;
}

.name-part {
    padding-top: 20px;
}

img.mobadampic {
    display: none;
}
.footer-left img.instgram {
    width: 32px !important;
}
.circaselect-box.score-new {
    margin: initial;
}
.circaselect-box.score-new1 {
    margin: inherit;
    float: right;
}
.Surviour-btn.learn-btn button{
    background: #fc5400;
    padding: 18px 55px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    border: none;
}
.mobile{
    display: none;
}



@media only screen and (max-width:768px) {
    .banner-content {
        position: absolute;
        top: inherit;
        max-width: 530px;
        transform: translateY(0%);
        left: auto;
        bottom: 10%;
       
    }
    .Surviour-btn.learn-btn button {
        display: inline-block;
    }
    .circaselect-box.score-new1 {
        float: left;
        margin-bottom: 20px;
    }

    .menu-btn {
        margin-right: 0;
    }
   
    .box-part h4 {
        font-size: 16px;font-weight: 800;}


    section.footer {
        padding: 20px 0px 0px 0px;
    }
    .background-image.video-part h5, h2 {
        padding: 0px 5%;
    }
    .article-headline-blk h2 {
        padding: 0px 0%;
        font-size: 30px !important;
    }
    .article-headline-blk .article-blk {
        padding: 30px;}
    .background-image.video-part .slick-slider {
        margin-top: 40px;
    }
    .adam-cpntent1 {
        padding-left: 20px;
    }
    .person-name1 .wade-mob {
        position: absolute;
        top: -76px;
        height: 274px;
        right: -25px;}
        .person-name1 .adam-mob {
            position: absolute;
            top: -89px;
            height: 274px;
        }   
    .pro-box {
        PADDING: 0PX 10PX;
    }
    .wade-part .name-part {
        padding-left: 15px;
    }
    .wade-part .lsit-part {
        padding-left: 15px;
    }
  
    .profile-box {
        padding: 20px 15px;}
    ul.lsit-part {
        margin-top: 20px;}
    img.mobadampic {
        display: block;
    }
    img.deskadampic {
        display: none;
    }

    .background-image {
        background-image: url('../../public/images/servingmobile.jpg');
    }

    #demo .carousel-indicators [data-bs-target] {
        width: 50px;}

    button.odd {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .table-image12 .slick-slide img {
        width: 73%;
    }
    .table-image12 .slick-prev {
        left: -12px;
    }

    .profile-box {
        height: auto;
        margin-bottom: 13px;}
    .banner-content h2{
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .Surviour-btn a {
        padding: 18px 75px;
        letter-spacing: 2px;
    }
    .select-box {
        text-align: left;
    }

    .main-2 {
        flex-direction: column;
    }

    .menu-center ul {
        flex-direction: column;
    }

    .menu-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu-center {
        display: none;
    }

    .click {
        display: block;
    }

    .mystyle {
        display: block;
        width: 100%;
        position: absolute;
        top: 191px;
        z-index: 99;
        background: #fc5400;
        padding-bottom: 25px;
    }

    .mystyle ul li {
        padding: 5px 20px;
    }

    /* .left-profile {
        width: 100%;
    } */

    .left-profile img {
        width: 100%;
    }

   

    .progile-img13 {
        padding-left: 0px;
    }

  

 

    .profile-part.wade-part {
        padding-left: 0px;
    }

    .profile-content {
        padding-top: 2px;
        text-align: justify;
        padding-left: 0px;
        padding-right: 0px;
    }

    .name-part img {
        width: 100%;
        max-width: 160px;
    }




    .learn-btn {
        margin-top: 22px;
        margin-bottom: 16px;
    }

    .learn-btn a {
        display: inline-block;
    }

    button.click i {
        font-size: 40px;
        color: #fff;
    }

    button.click {
        background: transparent;
        border: none;
    }

    .heading-serving1 h2 {
        font-weight: 800;
        font-size: 35px;
        margin-top: 20px;
        padding: 0px 10%;
        line-height: 45px;
    }

    .heading-serving1 {
        margin-bottom: 20px;
    }

    .box-part {
        padding: 25px;
    }

    section.slider-tabs {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .Meet h2 {
        font-size: 45px;
        margin-bottom: 60px;
        font-weight: 800;
    }

    .background-image.video-part {
        padding-top: 55px;
        background: url('../../public/images/backmob22.jpg');
        background-size: cover;
       
    }

    .background-image.video-part .heading-serving1 {
        margin-bottom: 15px;
    }

    section.custumtab {
        padding: 40px 0px;

    }

    .covers-CoversScoreboard-gameBox-teamAndOdds {
        display: flex;
        gap: 15px;
        flex-direction: column;
    }

    .slide-div {
        padding: 0px 20px;
    }

    .Kansas-box22 {
        padding: 0px 10px 9px 10px;
    }

    .covers-CoversScoreboard-gameBox-Status {
        margin-bottom: 20px;
    }

    .footer-left h4 {
        font-size: 27px;
        letter-spacing: 1px;
        margin-top: 50px;
    }

    .footerlogo {
        text-align: center;
        margin-top: 20px;
    }

    .list-menu ul {
        text-align: center;
    }

    .footer-left {
        text-align: center;
    }

    .list-menu ul {
        padding-left: 0px;
    }

    ul.list11 {
        justify-content: center;
        margin-bottom: 30px;
    }

    .footerlogo img {
        width: 200px;
    }

    .list-menu ul li {
        margin-bottom: 12px;
    }

    .list-menu ul li a {
        font-size: 20px;
    }

    .video-part .slick-next {
        right: -12px !important;
    }

    .video-part .slick-prev {
        left: -13px;
        position: absolute;
        z-index: 9;
    }

    section.custumtab ul.nav li {
        margin: 11px 17px 0px 0px;
    }

    .tab {
        width: 100%;
        height: auto;

    }

    .table23 .table-blk1 {
        margin-top: 8px;
        overflow-x: scroll;
    }

    .right-top img {
        width: 85%;
        object-fit: cover;
    }

    .tabcontent {
        float: none;
        width: 100%;
    }

    .slick-slide img {
        width: 100%;
    }

    .mobileview {
        display: block;
    }

    .desktopview {
        display: none !important;
    }

    .video-part .slick-slide {
        padding: 0px 0px;
    }



    .video-part .slick-dots li {
        margin: 0 0px !important;
    }

    .video-part .slick-dots li button:before {
        font-size: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        content: "";
        border: 2px solid #000;
        border-radius: 100%;
        opacity: 1;
    }

    .video-part .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
        opacity: 5;
    }

    .serving-mobile .slick-dots li button:before {
        font-size: 12px;
        height: 12px;
        width: 12px;
        display: inline-block;
        content: "";
        border: 2px solid #000;
        border-radius: 100%;
        opacity: 1;
    }

    .serving-mobile .slick-dots li.slick-active button:before {
        background: #fc5400;
        border-width: 0;
        opacity: 5;
    }

    .serving-mobile .slick-dots li {
        margin: 0 0px !important;
    }

    .box-part {
        min-height: 325px;
        margin-bottom: 40px;
    }
    .contact-form {
        max-width: 100% !important;
        margin: 35px auto !important;
        padding: 20px !important;
        margin-top: 30px !important;
    }
    .serving-mobile .learn-btn a {
        padding: 20px 70px;
        letter-spacing: 2px;
    }

    .serving-mobile .learn-btn.learnbtn1 {
        margin-top: 70px;
    }

    .background-image.desktop-backimg {
        display: none;
    }

    .background-image.serving-mobile {
        display: block;
        background-image: url('../../public/images/serving-mobileback.jpg');
        padding: 40px 0px;
    }

    ul.lsit-part li {
        position: relative;
        padding-left: 25px;
        font-size: 15px;
        font-weight: 600;
    }

    section.slider-tabs.about-page .Meet h2 {
        font-weight: 800;
        font-size: 45px;
        margin-top: 20px;
        line-height: 50px;
    }

    section.slider-tabs.about-page {
        background-color: #fff3e9;
        padding: 10px 0px;
    }

    .Meet {
        padding: 50px 15px;
    }

    ul.lsit-part li i {
        color: #fc5400;
        position: absolute;
        left: 0;
        top: 2px;
        font-size: 20px;
    }

  

    .copyright1 p {
        font-size: 16px;
    }

    .copyright1 {
        border-top: 4px solid #fff;
    }

    .desktop-footer {
        display: none;
    }

    .mobile-footer {
        display: block;
    }

    .list-menu {
        margin-top: 40px;
    }

    ul.list11 li img {
        width: 68px;
    }
    .footer-left img.instgram {
        width: 50px !important;
    }
    img.twitter1 {
        width: 48px !important;
    }

    .profile-content p {
        padding: 10px 10px 0px 10px;
        color: #0f2544;
        font-size: 18px;
        text-align: left;
    }
    .left-profile {
        padding: 0px 6px;
    }
    .sign-right {
        margin-left: 0px;
    }

    .adam-content {
        padding-top: 10px;
    }

    .menu-center ul li a:hover {
        color: #ffffff;
    }
    .profilemiddle {
        padding-right: 0;
        top: 0;
        position: relative;
        text-align: right;
    }
    .profile-last {
        top: 0;
        margin-bottom: 0;
    }

    .menu-center ul.dropdown-menu {
        width: 100%;
        text-align: left;
        background: #0f2544 !important;
        position: relative;
        transition: position linear 0.3s;
    }

    .menu-center ul li ul li {
        padding: 8px 10px !important;
    }

    .account1 {
        position: static;
    }

    .sign-right {
        position: relative;
    }

    .account1 ul {
        left: 0;
        right: 0;
        width: 100%;
        background: #0f2544;
        padding: 0;
        text-align: left;
        top: 40px;
    }
    .footer-left ul.list11 li {
        margin: 30px 20px 0px 20px;
    }
}


@media screen and (max-width: 376px) {
    img.mobadampic.wade-mob {
        width: 171px;
        height: 278px;
        object-fit: contain;
    } 
}